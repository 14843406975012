@font-face {
    font-family: 'Dalek';
    src: url('Dalek.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }

.bg {
    height: 100vh;
    width: 100vw;
    background-color: rgb(184, 59, 59);
    background-position: center;
    background-size: contain;
    display: flex;
    opacity : 20;
    align-items: center;
    justify-content: center;
    position: relative;
  }

.ventes{
    position: absolute;
    top : 200px;
    font-size:60px;  
    color:rgb(78, 20, 20);
    z-index: 1 ;
    font-family: 'Dalek';
}

.litres{
    position: absolute;
    bottom : 120px;
    font-size:100px;  
    color:rgb(78, 20, 20);
    z-index: 1 ;
    font-family: 'Dalek';
}

.biere{
    position: absolute;
    width: 100vw;
    left: 0px;
    z-index: 0 ;
    transition: .5s ease-in-out;
}

#barbarLogo{
    position:absolute;
    top : 100px;
    left : 100px;
    z-index: 1 ;
    size : 6in;
}

#footer {
    width: 100vw;
    height: 60px;
    background-color: black;
    color:white;
    text-align:center;
    position:absolute;
    bottom:0;
    z-index:200;
    font-size:40px;
}

#footer p {
    margin: 0;
}