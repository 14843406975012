.mainContainer {
    background-image: url("../../assets/permIF/fond_if.png");
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
}

.blurEffect {
    align-items: flex-end;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.logo {
    width: 70%;
}

.bars {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-self: flex-end;
    justify-content: space-evenly;
    align-items: center;
}

.header {
    color: white;
    font-family: "Roboto Slab", sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}