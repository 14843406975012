
@font-face {
    font-family: 'Dalek';
    src: url('../../../public/font/Sabo-Filled.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.body {
    height: 100%;
    width: auto;
    background-color: rgb(184, 59, 59);
    background-position: center;
    background-size: contain;
    display: flex;
    opacity : 20;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Dalek';
    font-size:15px;
    color:#ffffff;
}

.jauge {
    position: relative;
    bottom : 0px;
    height: 72.5px;
    background: #dcaf46;
    z-index: 1;
    box-shadow: 0px 0px 10px 2px rgba(220,175,70, 1);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.cuvee{
    position: absolute;
    left: 348px;
    top: 244px;
    z-index: 1;
}

.rasta{
    position: absolute;
    left: 348px;
    top: 423.5px;
    z-index: 1;
}

.lupulus{
    position: absolute;
    left: 348px;
    top: 609px;
    z-index: 1;
}

.valdieu{
    position: absolute;
    right: 338px;
    top: 244px;
    z-index: 1;
}

.stfeuillien{
    position: absolute;
    right: 338px;
    top: 423.5px;
    z-index: 1;
}

.rubus{
    position: absolute;
    right: 338px;
    top: 607px;
    z-index: 1;
}

.Nb_Gauche {
    position: relative;
    font-size:30px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
}

.Nb_Droit {
    position: relative;
    font-size:30px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}

.PtsGnome {
    position: absolute;
    font-size:120px;
    left: 25vw;
    bottom: -8vh;
    z-index: 1;

}

.PtsKnight {
    position: absolute;
    font-size:120px;
    right: 25vw;
    bottom: -8vh;
    z-index: 1;
}

.bg {
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);
    background-position: center;
    background-size: contain;
    display: flex;
    opacity : 20;
  }


  .gnome {
    height: 100px;
    background-color: rgba(255, 255, 255, 0);
    background-position: center;
    background-size: contain;
    display: flex;
    opacity : 20;
  }

  
  .chevalier {
    background-color: aqua;
    position: absolute;
    bottom: -8vh;
    right: 25vw;
    z-index: 1;
  }