.ranking-item-container {
    display: flex;
    flex-direction: row;
    padding: 0 30px;
}

.ranking-item-container h2 {
    margin: 0;
}

.ranking-item-container-rank-text {
    flex: 1;
    text-align: left;
}

.ranking-item-container-name-text {
    flex: 5;
    text-align: left;
}

.ranking-item-container-game-text {
    flex: 2;
    text-align: left;
}

.ranking-item-container-elo-text {
    flex: 1;
    text-align: end;
}