.chefBarContainer {
    display: flex;
    flex-direction: column;
    margin: 50px;
    padding-bottom: 50px;
}

.chefLogoBar {
    bottom: 50px;
    border-radius: 45%;
    border: 1px white;
    height: 100px;
    position: absolute;
    width: 100px;
}