.App {
  font-family: "Roboto", serif !important;
}

/*  SCROLLBAR  */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #888 ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #90a4ae ;
}


.responsive_table {
  width: 100%;
  overflow-x: auto;
}

.top10{
  margin-top: 10px;
}

.margin10{
  margin: 10px;
}

.basic_container {
  padding: 3%;
  margin: 4%;
  border: 2px solid #B22132;
}

/* ADMIN SIDE  */
.admin_container {
  padding: 3%;
  margin: 4%;
  margin-top: 100px;
  border: 2px solid #B22132;
}

.admin_loader {
  margin-top: 200px;
}
