
@font-face {
    font-family: 'Dalek';
    src: url('../../../public/font/Sabo-Filled.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }

.body {
    height: 100%;
    width: auto;
    background-color: rgb(184, 59, 59);
    background-position: center;
    background-size: contain;
    display: flex;
    opacity : 20;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Dalek';
    font-size:15px;  
    color:#ffffff;
    background-image: url('../../../public/images/BG_IF.png');
  }

.rectangle {
    position: relative;
    bottom : 0px;
    width: 125px;
    background: #499441;
    z-index: 1;
    box-shadow: 0px 0px 10px 5px rgba(255,255,255, .6);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }

.image{
  position: relative;
  size : 200px;
}

.dimanche{
    position: absolute;
    left: 65vw;
    bottom: 20vh;
    z-index: 1;
  }

.samedi{
    position: absolute;
    left: 26vw;
    bottom: 20vh;
    z-index: 1;
  }
