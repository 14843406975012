.chefMainContainer {
    background-image: url("../../assets/permChef/backgroundPermChef.jpg");
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
}

.chefBlurEffect {
    align-items: flex-end;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    -webkit-backdrop-filter: blur(5px); /* assure la compatibilité avec safari */
    backdrop-filter: blur(5px);
    background-color: rgba(182, 182, 182, 0.2);
}

.chefBars {
    align-items: flex-end;
    display: flex;
}

.chefHeader {
    color: white;
    font-family: "Roboto Slab", sans-serif;
    font-weight: bold;
    font-size: 5em;
    position: absolute;
    top: 0;
    text-shadow: 0 0 20px black;
}