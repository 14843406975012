@font-face {
  font-family: 'Dalek';
  src: url('../../../public/font/Sabo-Filled.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar {
  display: none; 
}

.aviator-body {
  border: black solid 1px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: 'Dalek';
  scrollbar-width: none; 
}


.game-container {
  background: black;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  background-size: 100% 500%;
  overflow: hidden;
}

.game-container.running {
  animation: background-up 2s linear forwards;
}

@keyframes background-up {
  from {
    background-position: var(--current-position, 0% 0%);
  }
  to {
    background-position: 100% 100%;
  }
}

.left-panel {
  color: gold;
  position: absolute;
  top: 3vh;
  left: 2vw;
  width: max-content;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  gap: 1rem;
  z-index: 20;
}

.left-panel .miseinput {
  height: 50px;
  width: 15rem;
  font-size: 40px;
  background-color: black;
  border: 3px outset gold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; 
  font-family: 'Dalek';
  text-align: center;
}


.setting-button .button:hover {
  background: #f3f3f3;
}


.ground {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 50%;
  background: linear-gradient(0deg, #ead200  0%, #c69b00 100%);
}

.ground.running{
  animation: ground-move 1s linear forwards; 
}

@keyframes ground-move {
  100% {
    height: 0;
  }
}


.middle-panel {
  padding-top: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.title {
  width: 60%;
}

.score-display {
  width: 300px;
  height: 100px;
  font-size: 70px;
  background-color: black;
  border: 3px outset gold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; 
}


.button-raw {
  display: flex;
  flex-direction: row;
  gap: 20px;
}


.button {
  font-family: 'Dalek';
  background-color: gold;
  border: 5px outset gold;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  touch-action: manipulation;
}

.button:hover {
  background-color: rgb(255, 187, 0);
  color: #fff;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}


.airplane-container {
  position: relative;
}

.airplane {
  width: 25rem;
  height: auto;
}


.airplane-container.running {
  animation: airplane-rotate 3s ease-out forwards;
}

@keyframes airplane-rotate {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-20deg);
  }
}

.result {
  margin-bottom: 5rem;
  height: 60px;
  font-size:60px;
  z-index: 1;
}


.right-panel {
  color: gold;
  position: absolute;
  top: 3vh;
  right: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.switch {
  --input-focus: gold;
  --font-color: white;
  --font-color-sub: white;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 50px;
  height: 20px;
}

.toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-colorcolor);
  transition: 0.3s;
}

.slider:before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  left: -2px;
  bottom: 2px;
  background-color: var(--bg-color);
  box-shadow: 0 3px 0 var(--main-color);
  transition: 0.3s;
}

.toggle:checked + .slider {
  background-color: var(--input-focus);
}

.toggle:checked + .slider:before {
  transform: translateX(30px);
}

.bill {
  position: absolute;
  width: auto;
  animation: defilement 2s linear;
  z-index: 0;
}

@keyframes defilement {
  from {
    transform: translateX(200px) rotate(500deg);
  }
  to {
    transform: translateX(-200vh) rotate(0deg);
  }
}