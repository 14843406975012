
.blurEffect {
    display: flex;
    height: 100vh;
    justify-content: center;
    -webkit-backdrop-filter: blur(3px); /* assure la compatibilité avec safari */
    backdrop-filter: blur(3px);
    background-color: rgba(182, 182, 182, 0.2);
}
.fight {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}
.attack {
    display: block;
    width: 1000px;
    height: 200%;
    position:relative;
    top: 100px;

}

.right {
    position: absolute;
    height: 51px;
    right : 30px;
    top:260px;
}

.left {
    position: absolute;
    height: 51px;
    left: 30px;
    top : 260px;
}

.vdhText {
    font-size: 4rem;
    color: white;
    text-align: center;
    margin: auto;
    width: 60%;
}

.montant {
    font-style: italic;
}
.beer_img {
    opacity: 50%;
    width: 100px;
    display: inline-block;
}

.beer_img-selected {
    opacity: 100%;
    box-shadow: 0px 0px 20px rgb(255 255 255);
    border: 5px solid yellow;
}

.beer_label {
    display:inline-block;
    margin: 10px;
}

.beer_wrapper {
    color:white;
    font-size:40px;
}

.top_info{
    position: absolute;
    top: 15px;
    width: 80vw;
    left: 10vw;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.HUD_halloween {
    width: 85%;
    border: 5px darkgrey solid;
    border-radius: 15px;
    overflow: hidden;
}

.logo_teddy{
    width: 10%;
    aspect-ratio: 1;
    border-radius: 50%;
}

.hp-rectangle {
    transition: .5s ease-in-out;
}

#footer {
    width: 100vw;
    height: 60px;
    background-color: black;
    color:white;
    text-align:center;
    position:absolute;
    bottom:0;
    z-index:200;
    font-size:40px;
}

#footer p {
    margin: 0;
}