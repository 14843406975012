.telethonperm {
    background: rgb(47,221,121);
    background: radial-gradient(circle, rgba(47,221,121,1) 0%, rgba(29,112,68,1) 69%, rgba(33,88,5,1) 100%);
    color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header {
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 70%;
    text-align: center;
}

.header > h1 {
    font-size: 2rem;
}

.header > p {
    font-size: 1.2rem;
    font-weight: normal;
}
